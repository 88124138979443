.google-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
  }
  
  .google-btn:hover {
    background-color: #000;
    color: #fff;
  }