.card-container {
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.avatar {
  background-color: #f3f3f3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 0 auto;
  margin-top: 24px;
  width: 200px;
}

.avatar img {
  border-radius: 50%;
  height: 90px;
  width: 90px;
}

.name {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 700;
  margin-top: 24px;
  text-align: center;
}

.details {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
}

.user-card-mobile__detail {
  color: #8c8c8c;
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
}

.user-card-mobile__toggle {
  background-color: #f3f3f3;
  border-radius: 50%;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  position: absolute;
  right: 16px;
  width: 48px;
}

.user-card-mobile__toggle svg {
  fill: #8c8c8c;
  height: 24px;
  width: 24px;
}

.user-card-mobile__toggle--open {
  transform: rotate(180deg);
}

.user-card-mobile__collapse {
  background-color: #f3f3f3;
  border-radius: 0px 0px 12px 12px;
  margin-top: 8px;
  overflow: hidden;
  padding: 16px;
  transition: height 0.3s ease-in-out;
  height: 0;
}

.user-card-mobile__collapse--open {
  height: auto;
}
